body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    background-color: #f4f4f4;
    scroll-behavior: smooth; 
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: Playfair_Italic;
    src: url("Font/PlayfairDisplay-Italic-VariableFont_wght.ttf");
}

@font-face {
    font-family: Playfair_Regular;
    src: url("Font/PlayfairDisplay-VariableFont_wght.ttf");
}

p {
    font-family: 'Times New Roman', Times, serif;
    text-align: justify;
    font-size: x-large;
    word-spacing: 0.1em;
    hyphens: auto;
    line-height: 1.7;
    @media screen and (max-width: 1024px) {
        font-size: 1.2rem;
    }
}

h1 {
    font-size: 50px;
    @media screen and (max-width: 1024px) {
        font-size: x-large;
      }
}

.Icono {
    background-color: white;
}

hr {
    margin-top: 150px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Times_new_roman;
}